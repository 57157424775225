<template>
  <page-content>
    <page-title heading="การจัดการผู้ดูแลระบบ">
      <template v-slot:right>
        <b-button class="mr-3" variant="danger" @click="exportReport">
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงาน</span>
        </b-button>

        <export-modal v-model="isExporting" />

        <b-button variant="danger" @click="onCreate"
          >เพิ่มรายชื่อผู้ดูแลระบบ</b-button
        >
      </template>
    </page-title>

    <b-row>
      <b-col cols="12" class="mb-2">
        <filter-master
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :hiddenInput="{ school: true, schoolClass: true, room: true }"
        >
        </filter-master>
      </b-col>

      <b-col sm="8" md="6" class="pr-sm-0">
        <b-form-group class="px-2">
          <b-input-group class="input-group-seamless">
            <b-form-input
              placeholder="ค้นหารายชื่อผู้ดูแลระบบ (ชื่อ-สกุล, เบอร์โทรศัพท์, เลขบัตรประชาชน หรือ อีเมล)"
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="4" md="2" class="px-sm-2 px-4">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col>
    </b-row>

    <user-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="userTableParams"
      @edit="onEdit"
      @delete="onDelete"
    />

    <create-or-update-user
      :edit-data.sync="editData"
      user-type="ADMIN"
      v-model="showModalAdmin"
      @create:success="onCreateSuccess"
    ></create-or-update-user>

    <delete-user
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteData"
    ></delete-user>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateUser from "../../components/modal/CreateOrUpdateUser";
import UserTable from "../../components/table/User";
import FilterMaster from "../../components/form/FilterMaster";
import DeleteUser from "../../components/modal/DeleteUser";
import { Auth } from "../../models";
import reportMixin from "../../mixins/reportMixin";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import ExportModal from "../../components/modal/Export";

export default {
  mixins: [reportMixin],

  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateUser,
    UserTable,
    DeleteUser,
    FilterMaster,
    ExportModal,
  },

  data() {
    return {
      keyword: "",
      formfilter: {},
      editData: {},
      deleteData: {},
      showModalAdmin: false,
      showModalDelete: false,
      isExporting: false,
      fields: [
        // { key: "order", label: "ลำดับ" },
        // { key: "rank", label: "ยศ" },
        // { key: "first_name", label: "ชื่อ" },
        // { key: "last_name", label: "สกุล" },
        { key: "user_name", label: "ยศ ชื่อ-สกุล", sortable: true },
        { key: "position_name", label: "ตำแหน่ง", sortable: true },
        { key: "phone_number", label: "เบอร์โทรศัพท์", sortable: true },
        { key: "birth_date", label: "วัน/เดือน/ปีเกิด", sortable: true },
        // { key: "email", label: "อีเมล" },
        { key: "retire_year", label: "ปีเกษียณ", sortable: true },
        {
          key: "total_time",
          label: "จำนวนชั่วโมงการสอน (ชั่วโมง)",
          sortable: true,
        },
        { key: "total_year", label: "จำนวนปีที่สอน (ปี)" },
        { key: "start_year", label: "ปีที่เริ่มสอน พ.ศ.", sortable: true },
        { key: "medal", label: "ระดับเข็ม", sortable: true },
        { key: "model_no", label: "รุ่นครูตำรวจแดร์", sortable: true },
        { key: "user_status", label: "สถานะ", sortable: true },
        {
          key: "created_at",
          label: "วันที่สร้าง",
        },
      ],
      exportFields: {
        หน่วย: "m_headquarter",
        จังหวัด: "m_division",
        สถานี: "m_station",
        "ยศ ชื่อ-นามสกุล": "user_name",
        ตำแหน่ง: "position_name",
        สิทธิ์ผู้ใช้งาน: "user_role",
        เบอร์โทรศัพท์: "phone_number",
        "วัน/เดือน/ปีเกิด": "birth_date",
        ปีเกษียณ: "retire_year",
        "จำนวนชั่วโมงการสอน (ชั่วโมง)": "total_time",
        "จำนวนปีที่สอน (ปี)": "total_year",
        "ปีที่เริ่มสอน พ.ศ.": "start_year",
        ระดับเข็ม: "medal",
        รุ่นครูตำรวจแดร์: "model_no",
        สถานะ: "user_status",
      },
    };
  },

  // watch: {
  //   formfilter: {
  //     deep: true,
  //     handler: "onFormFilterChanged",
  //   },
  // },

  computed: {
    authUser() {
      return Auth.user();
    },

    userTableParams() {
      const { formfilter } = this;

      return {
        ...formfilter,
        user_type: "ADMIN",
      };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    // onFormFilterChanged(formFilter) {
    //   const query = {};
    //   const { authUser } = this;

    //   if (!authUser.mHeadquarterId && formFilter.mHeadquarterId) {
    //     this.$set(query, "mHeadquarterId", formFilter.mHeadquarterId);
    //   }

    //   if (!authUser.mDivisionId && formFilter.mDivisionId) {
    //     this.$set(query, "mDivisionId", formFilter.mDivisionId);
    //   }

    //   if (!authUser.mStationId && formFilter.mStationId) {
    //     this.$set(query, "mStationId", formFilter.mStationId);
    //   }

    //   console.log(query, formFilter);
    //   if (Object.keys(query).length) {
    //     this.$router.replace({
    //       name: "MasterDataTeacher",
    //       query,
    //     });
    //   }
    // },

    async fetchExport() {
      let data = [];

      this.isExporting = true;

      try {
        data = await this.$refs.table.fetch({ downloadAll: true });
      } catch (error) {
        this.$toast.error("ไม่สามารถดึงข้อมูลรายงานได้ กรุณาลองใหม่ภายหลัง");
      } finally {
        this.isExporting = false;
      }

      return data;
    },

    async exportReport() {
      let data = await this.fetchExport();

      if (data && data.length) {
        const wb = this.exportOnePage(data);

        /* bookType can be any supported output type */
        const wopts = { bookType: "xlsx", bookSST: false, type: "array" };

        const wbout = XLSX.write(wb, wopts);

        /* the saveAs call downloads a file on the local machine */
        saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "รายชื่อผู้ดูแลระบบ.xlsx"
        );
      }
    },

    exportOnePage(data) {
      let exportFields = { ...this.exportFields };

      if (this.formfilter.mStationId) {
        this.$delete(exportFields, "สถานี");
      }

      let header = Object.keys(exportFields);

      const wb = XLSX.utils.book_new();

      let sheetHeader = ["ลำดับ", ...header];

      data = data.map((record, index) => {
        const res = {
          ลำดับ: index + 1,
        };

        header.forEach((col) => {
          res[col] = record[exportFields[col]];
        });

        return res;
      });

      let ws = XLSX.utils.json_to_sheet(data, {
        header: sheetHeader,
      });

      ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
        orderCol: "A",
      });

      ws = this.$_reportMixin_setWidthColumn(ws, sheetHeader, data);

      XLSX.utils.book_append_sheet(wb, ws, "รายชื่อผู้ดูแลระบบ");

      return wb;
    },

    onCreate() {
      this.showModalAdmin = true;
    },

    async onEdit(item) {
      this.editData = { ...item };

      //   const promise = await User.api().findAll({
      //     teachingDareId: item.id,
      //   });

      //   const { data = [] } = promise.response.data;

      //   if (data.length) {
      //     this.$set(
      //       this.editData,
      //       "teaching_plans",
      //       sortBy(data, "createdAt").map(
      //         ({ lesson_number, lesson_name, date, time, content }) => ({
      //           lesson_number,
      //           lesson_name,
      //           date,
      //           time,
      //           content,
      //         })
      //       )
      //     );
      //   }

      this.showModalAdmin = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
